/* eslint-disable no-loop-func */
import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";

import { random } from "../../utils";
import { useMediaQuery } from "../../hooks/useMediaQuery";

import {
  openAbout,
  openNotepad,
  openModal,
  openContact,
  openWeRunAds,
  openFaq,
} from "../../store/actions/actions";
import { cursorSetter } from "../../styles/cursorSetter";
import Icon from "./Icon";
import Notepad from "../../assets/desktop-icons/Notepad.png";
import console_prompt from "../../assets/desktop-icons/console_prompt-0.png";
import Ads from "../../assets/desktop-icons/ads.png";
import About from "../../assets/desktop-icons/About.png";
import Faq from "../../assets/desktop-icons/contact-icon.png";
import WeRunAds from "../../assets/desktop-icons/contact-icon.png";
import Meeting from "../../assets/desktop-icons/meeting.png";
import Linkedin from "../../assets/desktop-icons/Linkedin95.png";

function distance(x1, y1, x2, y2) {
  return Math.abs(x1 - x2) + Math.abs(y1 - y2);
}

function iconsNear(x, y, positions) {
  return positions.filter(([x2, y2]) => distance(x, y, x2, y2) <= 1).length > 0;
}
const StyledIcons = styled.div`
  display: flex;
  flex-direction: column; // Keep icons in a column
  align-items: center; // Center align horizontally
  justify-content: center; // Center align vertically
  position: absolute;
  padding: 32px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

function Icons({
  onOpenAbout,
  onOpenFaq,
  onOpenContact,
  onOpenNotepad,
  onOpenWeRunAds,
  onOpenModal,
}) {
  const [icons, setIcons] = useState([
    { label: "README.txt", img: About, clicked: false },
    { label: "Notepad", img: Notepad, clicked: false },
    { label: "Linkedin", img: Linkedin, clicked: false },
    { label: "Creatives", img: Ads, clicked: false },
    { label: "Apply Here", img: Faq, clicked: false },
    { label: "zucc", img: console_prompt, clicked: false },
  ]);
  const navigate = useNavigate();
  const isCreatives = useMatch("/creatives");
  const isAbout = useMatch("/about");
  const isNodepad = useMatch("/notepad");
  const isWeRunAds = useMatch("/zucc");
  const isBookMeeting = useMatch("/book-meeting");
  const isFaq = useMatch("/apply");

  const sm = useMediaQuery("sm");
  const md = useMediaQuery("md");

  const positions = useMemo(() => {
    return icons.map((_, index) => ({
      left: "0%", // Align to the left
      top: `${index * 10}%`, // Stack vertically
    }));
  }, [icons.length]);

  useEffect(() => {
    if (isCreatives) {
      onOpenContact();
    }
  }, [isCreatives, onOpenContact]);

  useEffect(() => {
    if (isAbout) {
      onOpenAbout();
    }
  }, [isAbout, onOpenAbout]);

  useEffect(() => {
    if (isFaq) {
      onOpenFaq();
    }
  }, [isFaq, onOpenFaq]);

  useEffect(() => {
    if (isWeRunAds) {
      onOpenWeRunAds();
    }
  }, [isWeRunAds, onOpenWeRunAds]);

  useEffect(() => {
    if (isNodepad) {
      onOpenNotepad();
    }
  }, [isNodepad, onOpenNotepad]);

  useEffect(() => {
    if (isBookMeeting) {
      window.open("https://linkedin.com/in/jordanlingohr", "_blank");
    }
  }, [isBookMeeting]);

  useEffect(() => {
    window.addEventListener("click", resetIcons);

    return () => {
      window.removeEventListener("click", resetIcons);
    };
    // eslint-disable-next-line
  }, []);

  function handleClick(label) {
    const index = icons.findIndex((icon) => icon.label === label);
    const updatedIcons = [...icons];

    updatedIcons.map((icon) => (icon.clicked = false));
    updatedIcons[index].clicked = true;
    setIcons(updatedIcons);
  }

  function resetIcons(event) {
    if (!document.querySelector("#Icons").contains(event.target)) {
      reset();
    }
  }

  function reset() {
    const updatedIcons = [...icons];
    updatedIcons.map((icon) => (icon.clicked = false));
    setIcons(updatedIcons);
  }

  function onClickIcon(label) {
    reset();
    cursorSetter(600);
    if (label === "README.txt") {
      navigate("/about");
    } else if (label === "Notepad") {
      navigate("/notepad");
    } else if (label === "Linkedin") {
      window.open("https://linkedin.com/in/jordanlingohr");
    } else if (label === "Creatives") {
      navigate("/creatives");
    } else if (label === "Apply Here") {
      navigate("/apply");
    } else if (label === "zucc") {
      navigate("/zucc");
    } else {
      onOpenModal();
    }
  }

  function handleDragEnd(label, top, left) {
    const updatedIcons = icons.map(icon => 
      icon.label === label ? { ...icon, position: { top, left } } : icon
    );
    setIcons(updatedIcons);
  }

  return (
    <StyledIcons id="Icons">
      {icons.map(({ label, img, clicked, position }, index) => (
        <div key={label} style={{ margin: '30px 0' }}> {/* Wrapper div for spacing */}
          <Icon
            label={label}
            img={img}
            clicked={clicked}
            handleClick={onClickIcon}
            initialTop={position ? position.top : positions[index].top}
            initialLeft={position ? position.left : positions[index].left}
            onDragEnd={handleDragEnd}
          />
        </div>
      ))}
    </StyledIcons>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    onOpenAbout: () => dispatch(openAbout()),
    onOpenContact: () => dispatch(openContact()),
    onOpenFaq: () => dispatch(openFaq()),
    onOpenNotepad: () => dispatch(openNotepad()),
    onOpenWeRunAds: () => dispatch(openWeRunAds()),
    onOpenModal: () => dispatch(openModal()),
  };
};

export default connect(null, mapDispatchToProps)(Icons);
