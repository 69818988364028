import * as actionTypes from '../actions/actionTypes';

const initialState = {
  showStart: false,
  showModal: false,
  about: {
    show: false,
    blurred: false,
    minimized: false
  },
  contact: {
    show: false,
    blurred: false,
    minimized: false
  },
  notepad: {
    show: false,
    blurred: false,
    minimized: false
  },
  faq: {
    show: false,
    blurred: false,
    minimized: false
  },
  werunads: {
    show: false,
    blurred: false,
    minimized: false
  },
  shutDown: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHUT_DOWN:
      return {
        ...state,
        about: {
          ...state.about
        },
        notepad: {
          ...state.notepad
        },
        contact: {
          ...state.contact
        },
        faq: {
          ...state.faq
        },
        werunads: {
          ...state.werunads
        },
        shutDown: true
      }

    case actionTypes.START_BUTTON_CLICKED:
      return {
        ...state,
        about: {
          ...state.about,
          blurred: true
        },
        contact: {
          ...state.contact,
          blurred: true
        },
        notepad: {
          ...state.notepad,
          blurred: true
        },
        faq: {
          ...state.faq,
          blurred: true
        },
        werunads: {
          ...state.werunads,
          blurred: true
        },
        showStart: !state.showStart
      }

    case actionTypes.START_MENU_BLUR:
      return {
        ...state,
        about: {
          ...state.about
        },
        contact: {
          ...state.contact
        },
        notepad: {
          ...state.notepad
        },
        faq: {
          ...state.faq
        },
        werunads: {
          ...state.werunads
        },
        showStart: false
      }

    case actionTypes.OPEN_MODAL:
      return {
        ...state,
        about: {
          ...state.about,
          blurred: true
        },
        contact: {
          ...state.contact,
          blurred: true
        },
        faq: {
          ...state.faq,
          blurred: true
        },
        notepad: {
          ...state.notepad
        },
        werunads: {
          ...state.werunads,
          blurred: true
        },
        showModal: true
      }

    case actionTypes.OPEN_NOTEPAD:
      return {
        ...state,
        about: {
          ...state.about,
          blurred: true
        },
        contact: {
          ...state.contact,
          blurred: true
        },
        faq: {
          ...state.faq,
          blurred: true
        },
        werunads: {
          ...state.werunads,
          blurred: true
        },
        notepad: {
          show: true,
          blurred: false,
          minimized: false
        }
      }

    case actionTypes.OPEN_ABOUT:
      return {
        ...state,
        about: {
          show: true,
          blurred: false,
          minimized: false
        },
        notepad: {
          ...state.notepad,
          blurred: true
        },
        werunads: {
          ...state.werunads,
          blurred: true
        },
        faq: {
          ...state.faq,
          blurred: true
        },
        contact: {
          ...state.contact,
          blurred: true
        }
      }

      case actionTypes.OPEN_FAQ:
        return {
          ...state,
          faq: {
            show: true,
            blurred: false,
            minimized: false
          },
          notepad: {
            ...state.notepad,
            blurred: true
          },
          werunads: {
            ...state.werunads,
            blurred: true
          },
          about: {
            ...state.faq,
            blurred: true
          },
          contact: {
            ...state.contact,
            blurred: true
          }
        }

      case actionTypes.OPEN_CONTACT:
        return {
          ...state,
          contact: {
            show: true,
            blurred: false,
            minimized: false
          },
          about: {
            ...state.about,
            blurred: true
          },
          faq: {
            ...state.faq,
            blurred: true
          },
          werunads: {
            ...state.werunads,
            blurred: true
          },
          notepad: {
            ...state.notepad,
            blurred: true
          }
        }

        case actionTypes.OPEN_WERUNADS:
          return {
            ...state,
            werunads: {
              show: true,
              blurred: false,
              minimized: false
            },
            about: {
              ...state.about,
              blurred: true
            },
            faq: {
              ...state.faq,
              blurred: true
            },
            contact: {
              ...state.contact,
              blurred: true
            },
            notepad: {
              ...state.notepad,
              blurred: true
            }
          }

    case actionTypes.FOCUS_NOTEPAD:
      return {
        ...state,
        about: {
          ...state.about
        },
        contact: {
          ...state.contact
        },
        werunads: {
          ...state.werunads
        },
        notepad: {
          ...state.notepad,
          blurred: false
        },
        faq: {
          ...state.faq,
        }
      }

      case actionTypes.FOCUS_FAQ:
        return {
          ...state,
          about: {
            ...state.about
          },
          contact: {
            ...state.contact
          },
          werunads: {
            ...state.werunads
          },
          faq: {
            ...state.faq,
            blurred: false
          },
          notepad: {
            ...state.notepad
          }
        }

    case actionTypes.FOCUS_ABOUT: {
      return {
        ...state,
        about: {
          ...state.about,
          blurred: false
        },
        notepad: {
          ...state.notepad
        },
        contact: {
          ...state.contact
        },
        werunads: {
          ...state.werunads
        },
        faq: {
          ...state.faq
        }
      }
    }

    case actionTypes.FOCUS_CONTACT: {
      return {
        ...state,
        contact: {
          ...state.contact,
          blurred: false
        },
        notepad: {
          ...state.notepad
        },
        about: {
          ...state.about
        },
        werunads: {
          ...state.werunads
        },
        faq: {
          ...state.faq
        }
      }
    }

    case actionTypes.FOCUS_WERUNADS: {
      return {
        ...state,
        werunads: {
          ...state.werunads,
          blurred: false
        },
        notepad: {
          ...state.notepad
        },
        about: {
          ...state.about
        },
        werunads: {
          ...state.werunads
        },
        contact: {
          ...state.contact
        },
        faq: {
          ...state.faq
        }
      }
    }

    case actionTypes.BLUR_NOTEPAD:
      return {
        ...state,
        about: {
          ...state.about
        },
        contact: {
          ...state.contact
        },
        faq: {
          ...state.faq
        },
        werunads: {
          ...state.werunads
        },
        notepad: {
          ...state.notepad,
          blurred: true,
        }
      }

      case actionTypes.BLUR_FAQ:
        return {
          ...state,
          about: {
            ...state.about
          },
          contact: {
            ...state.contact
          },
          notepad: {
            ...state.notepad
          },
          werunads: {
            ...state.werunads
          },
          faq: {
            ...state.faq,
            blurred: true,
          }
        }

    case actionTypes.BLUR_ABOUT:
      return {
        ...state,
        about: {
          ...state.about,
          blurred: true
        },
        notepad: {
          ...state.notepad
        },
        contact: {
          ...state.contact
        },
        werunads: {
          ...state.werunads
        },
        faq: {
          ...state.faq
        }
      }

      case actionTypes.BLUR_CONTACT:
        return {
          ...state,
          contact: {
            ...state.contact,
            blurred: true
          },
          notepad: {
            ...state.notepad
          },
          about: {
            ...state.about
          },
          werunads: {
            ...state.werunads
          },
          faq: {
            ...state.faq
          }
        }

      case actionTypes.BLUR_WERUNADS:
        return {
          ...state,
          werunads: {
            ...state.werunads,
            blurred: true
          },
          notepad: {
            ...state.notepad
          },
          about: {
            ...state.about
          },
          contact: {
            ...state.contact
          },
          faq: {
            ...state.faq
          }
        }

    case actionTypes.MINIMIZE_NOTEPAD:
      return {
        ...state,
        about: {
          ...state.about
        },
        contact: {
          ...state.contact
        },
        faq: {
          ...state.faq
        },
        werunads: {
          ...state.werunads
        },
        notepad: {
          ...state.notepad,
          minimized: true
        }
      }

    case actionTypes.MINIMIZE_ABOUT:
      return {
        ...state,
        notepad: {
          ...state.notepad
        },
        faq: {
          ...state.faq
        },
        contact: {
          ...state.contact
        },
        werunads: {
          ...state.werunads
        },
        about: {
          ...state.about,
          minimized: true
        }
      }

      case actionTypes.MINIMIZE_FAQ:
        return {
          ...state,
          notepad: {
            ...state.notepad
          },
          about: {
            ...state.about
          },
          werunads: {
            ...state.werunads
          },
          contact: {
            ...state.contact
          },
          faq: {
            ...state.faq,
            minimized: true
          }
        }

      case actionTypes.MINIMIZE_CONTACT:
        return {
          ...state,
          notepad: {
            ...state.notepad
          },
          about: {
            ...state.about
          },
          faq: {
            ...state.faq
          },
          werunads: {
            ...state.werunads
          },
          contact: {
            ...state.contact,
            minimized: true
          }
        }

        case actionTypes.MINIMIZE_WERUNADS:
          return {
            ...state,
            notepad: {
              ...state.notepad
            },
            about: {
              ...state.about
            },
            faq: {
              ...state.faq
            },
            contact: {
              ...state.werunads
            },
            werunads: {
              ...state.werunads,
              minimized: true
            }
          }

    case actionTypes.MAXIMIZE_NOTEPAD:
      return {
        ...state,
        about: {
          ...state.about
        },
        contact: {
          ...state.contact
        },
        faq: {
          ...state.faq
        },
        werunads: {
          ...state.werunads
        },
        notepad: {
          ...state.notepad,
          blurred: false,
          minimized: false
        }
      }

    case actionTypes.MAXIMIZE_ABOUT:
      return {
        ...state,
        notepad: {
          ...state.notepad
        },
        contact: {
          ...state.contact
        },
        werunads: {
          ...state.werunads
        },
        faq: {
          ...state.faq
        },
        about: {
          ...state.about,
          minimized: false,
          blurred: false
        }
      }

      case actionTypes.MAXIMIZE_FAQ:
        return {
          ...state,
          notepad: {
            ...state.notepad
          },
          contact: {
            ...state.contact
          },
          werunads: {
            ...state.werunads
          },
          about: {
            ...state.about
          },
          faq: {
            ...state.faq,
            minimized: false,
            blurred: false
          }
        }

      case actionTypes.MAXIMIZE_CONTACT:
        return {
          ...state,
          notepad: {
            ...state.notepad
          },
          about: {
            ...state.about
          },
          werunads: {
            ...state.werunads
          },
          faq: {
            ...state.faq
          },
          contact: {
            ...state.contact,
            minimized: false,
            blurred: false
          }
        }

        case actionTypes.MAXIMIZE_WERUNADS:
          return {
            ...state,
            notepad: {
              ...state.notepad
            },
            about: {
              ...state.about
            },
            contact: {
              ...state.contact
            },
            faq: {
              ...state.faq
            },
            werunads: {
              ...state.werunads,
              minimized: false,
              blurred: false
            }
          }

    case actionTypes.EXIT_NOTEPAD:
      return {
        ...state,
        about: {
          ...state.about
        },
        contact: {
          ...state.contact
        },
        faq: {
          ...state.faq
        },
        werunads: {
          ...state.werunads
        },
        notepad: {
          show: false,
          minimized: false,
          blurred: false
        }
      }

    case actionTypes.EXIT_ABOUT:
      return {
        ...state,
        notepad: {
          ...state.notepad
        },
        contact: {
          ...state.contact
        },
        faq: {
          ...state.faq
        },
        werunads: {
          ...state.werunads
        },
        about: {
          show: false,
          blurred: false,
          minimized: false
        }
      }

      case actionTypes.EXIT_FAQ:
        return {
          ...state,
          notepad: {
            ...state.notepad
          },
          contact: {
            ...state.contact
          },
          werunads: {
            ...state.werunads
          },
          about: {
            ...state.about
          },
          faq: {
            show: false,
            blurred: false,
            minimized: false
          }
        }

      case actionTypes.EXIT_CONTACT:
        return {
          ...state,
          notepad: {
            ...state.notepad
          },
          about: {
            ...state.about
          },
          faq: {
            ...state.faq
          },
          werunads: {
            ...state.werunads
          },
          contact: {
            show: false,
            blurred: false,
            minimized: false
          }
        }

    case actionTypes.EXIT_MODAL:
      return {
        ...state,
        about: {
          ...state.about
        },
        contact: {
          ...state.contact
        },
        werunads: {
          ...state.werunads
        },
        notepad: {
          ...state.notepad
        },
        faq: {
          ...state.faq
        },
      }

      case actionTypes.EXIT_WERUNADS:
        return {
          ...state,
          about: {
            ...state.about
          },
          contact: {
            ...state.contact
          },
          werunads: {
            show: false,
            blurred: false,
            minimized: false
          },
          notepad: {
            ...state.notepad
          },
          faq: {
            ...state.faq
          },
          showModal: false
        }

    default: return state;
  }
}

export default reducer;