import React, { useEffect } from "react";
import styled from "styled-components";
import { connect, useDispatch } from "react-redux";
import contactImage from "../../assets/taskbar-icons/ads-small.png";
import WindowFrame from "./WindowFrame/WindowFrame";
import {
  focusContact,
  blurContact,
  minimizeContact,
  exitContact,
} from "../../store/actions/actions";
import Carousel from "../Carousel";
import YYC1 from "../../assets/ads/yyc.gif";
import YYC2 from "../../assets/ads/yyc2.gif";
import MAD1 from "../../assets/ads/mad.gif";
import MAD2 from "../../assets/ads/mad2.jpg";
import MAD3 from "../../assets/ads/mad3.jpg";
import CRA from "../../assets/ads/cra.gif";
import YYC3 from "../../assets/ads/yyc3.gif";
import YYC4 from "../../assets/ads/yyc4.png";
import { useNavigate } from "react-router-dom";

const images = [YYC1, CRA, MAD1, YYC2, MAD2, YYC3, MAD3, YYC4];

const StyledContainer = styled.div`
  overflow: clip;
  padding-top: 0px;
  .images img {
    width: 80px;
    height: 90px;
  }
  .container {
    display: flex;
    padding-right: 15px;
    .text {
      padding-left: 15px;
      flex: 2;
      line-height: 18px;
      width: 100%;
      a {
        text-decoration: underline;
        color: rgb(0, 0, 127);
      }
    }
  }
  .button-container {
    padding-top: 15px;
    text-align: right;
  }
`;

function Contact({
  contact,
  onContactFocus,
  onContactBlur,
  onContactMinimize,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    window.addEventListener("click", contactBlur);
    return () => window.removeEventListener("click", contactBlur);
    // eslint-disable-next-line
  }, []);

  function contactBlur(event) {
    if (
      document.querySelector("#About") &&
      !document.querySelector("#About").contains(event.target) &&
      !document.querySelector("#about-button").contains(event.target)
    ) {
      onContactBlur();
    } else {
      onContactFocus();
    }
  }
  const handleClickExit = () => {
    dispatch(exitContact());
    navigate("/");
  };

  const displayContent = contact.show ? (
    <WindowFrame
      id="Creatives"
      x="140"
      y="100"
      img={contactImage}
      width="1165"
      height="285"
      title="Our Ads"
      blurred={contact.blurred}
      showMenu={false}
      onMinimize={onContactMinimize}
      onExit={handleClickExit}
      isMinimized={contact.minimized}
    >
      <StyledContainer>
       <div className="container">
            <div className="text">
            <h1>Facebook creatives designed to convert</h1>
            <p>You're only ever one ad away from scaling your brand to new heights. Content creation is by far one of the most high-yielding investments into your brand that you could make.</p>
            <div className="carousel">
              <Carousel images={images} />
            </div>
          </div>
        </div>
      </StyledContainer>
    </WindowFrame>
  ) : null;

  return displayContent;
}

const mapStateToProps = (state) => {
  return {
    contact: state.contact,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onContactBlur: () => dispatch(blurContact()),
    onContactFocus: () => dispatch(focusContact()),
    onContactMinimize: () => dispatch(minimizeContact()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
