import React, { useEffect, useRef } from 'react';

const Carousel = ({ images }) => {
  const scrollRef = useRef(null);

  useEffect(() => {
    const scrollElement = scrollRef.current;

    const scrollFunction = () => {
      if (scrollElement) {
        if (scrollElement.scrollLeft === scrollElement.scrollWidth - scrollElement.clientWidth) {
          scrollElement.scrollLeft = 0;
        } else {
          scrollElement.scrollLeft += 1;
        }
      }
    };

    const intervalId = setInterval(scrollFunction, 50);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <style>
        {`
          .carousel-container {
            position: relative;
            overflow: hidden;
          }
          .gradient-left {
            position: absolute;
            top: 0;
            left: 0;
            width: 2rem;
            height: 100%;
            background: linear-gradient(to left, transparent, rgb(255, 255, 255));
          }
          .gradient-right {
            position: absolute;
            top: 0;
            right: 0;
            width: 2rem;
            height: 100%;
            background: linear-gradient(to right, transparent, rgb(255, 255, 255));
          }
          .carousel-inner {
            white-space: nowrap;
            overflow: auto;
          }
          .carousel-item {
            display: inline-block;
            height: 30rem;
            object-fit: cover;
            margin: 0 0.5rem;
            border: 2px solid #000;
          }
          .carousel {
            align: center;
            max-width: 90rem;
            margin-left: auto;
            margin-right: auto;
        }
        `}
      </style>
      <div className="carousel-container">
        <div className="gradient-left"></div>
        <div className="gradient-right"></div>
        <div ref={scrollRef} className="carousel-inner">
          {images.map((image, index) => (
            <img className="carousel-item" src={image} alt={`Slide ${index}`} key={index} />
          ))}
          {images.map((image, index) => (
            <img className="carousel-item" src={image} alt={`Slide ${index}`} key={index + images.length} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Carousel;