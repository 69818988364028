import React, { useState, useEffect, useRef } from "react";

import { StyledFrame, TitleBar, ButtonGroup, StyledMenu } from "./FrameStyled";
import minimize from "../../../assets/titlebar-icons/minimize.png";
import maximizeDisabled from "../../../assets/titlebar-icons/maximize-disabled.png";
import close from "../../../assets/titlebar-icons/close.png";
import { useMediaQuery } from "../../../hooks/useMediaQuery";

import styled from "styled-components"; // Ensure styled-components is imported

// Add this styled component for the overlay with Gaussian blur and transition
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); // Semi-transparent black
  backdrop-filter: blur(3px); // Apply Gaussian blur
  z-index: 5; // Ensure it's below the window but above other content
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)}; // Control visibility
`;

function random(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function clamp(value, min, max) {
  return Math.min(Math.max(min, value), max);
}

function Frame({
  children,
  id,
  img,
  title,
  blurred,
  showMenu,
  width,
  onMinimize,
  onExit,
  isMinimized,
}) {
  const frameRef = useRef(null);

  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });

  // Update coordinates after the frame is rendered
  useEffect(() => {
    const maxX = window.innerWidth - frameRef.current?.offsetWidth || 0;
    const maxY = window.innerHeight - frameRef.current?.offsetHeight - 32 || 0;

    // Center the window
    setCoordinates({ x: maxX / 2, y: maxY / 2 });
  }, [frameRef.current]); // Run when frameRef is set

  const [offset, setOffset] = useState({ x: coordinates.x, y: coordinates.y });

  const isLg = useMediaQuery("sm");

  useEffect(() => {
    const frameTitle = document.querySelector("#" + id + " .title");
    frameTitle.addEventListener("mousedown", dragStart);

    return () => frameTitle.removeEventListener("mousedown", dragStart);
    // eslint-disable-next-line
  }, [offset]);

  function dragStart(event) {
    window.onmousemove = (e) =>
      dragging(e, { x: event.clientX, y: event.clientY });
    window.onmouseup = dragEnd;
  }

  function dragging(event, axis) {
    let x = axis.x - event.clientX;
    let y = axis.y - event.clientY;
    const frameHeight = frameRef.current?.offsetHeight;
    const frameWidth = frameRef.current?.offsetWidth;

    const clampedX = clamp(
      coordinates.x - x,
      0,
      window.innerWidth - frameWidth
    );

    const clampedY = clamp(
      coordinates.y - y,
      0,
      window.innerHeight - frameHeight - 32
    );

    setOffset({ x: event.clientX, y: event.clientY });
    setCoordinates({ x: clampedX, y: clampedY });
  }

  function dragEnd() {
    window.onmousemove = null;
    window.onmouseup = null;
  }

  const menu = showMenu ? (
    <StyledMenu>
      <span>
        <u>F</u>ile
      </span>
      <span>
        <u>E</u>dit
      </span>
      <span>
        <u>S</u>earch
      </span>
      <span>
        <u>H</u>elp
      </span>
    </StyledMenu>
  ) : null;

  // State to control the visibility of the overlay
  const [isOverlayVisible, setIsOverlayVisible] = useState(true); // Set to true when the window opens

  // Use effect to manage overlay visibility
  useEffect(() => {
    if (isMinimized) {
      setIsOverlayVisible(false); // Hide overlay when minimized
    } else {
      setIsOverlayVisible(true); // Show overlay when window is open
    }
  }, [isMinimized]);

  return (
    <>
      <Overlay isVisible={isOverlayVisible} /> {/* Pass visibility state */}
      <StyledFrame
        left={isLg ? coordinates.x : 5}
        top={isLg ? coordinates.y : 5}
        id={id}
        width={width}
        isMinimized={isMinimized}
        blurred={blurred}
        ref={frameRef}
      >
        <TitleBar blurred={blurred}>
          <img src={img} alt="Window" draggable="false" />

          <span className="title">{title}</span>

          <ButtonGroup>
            <button className="clickable" onClick={() => onMinimize()}>
              <img src={minimize} draggable="false" alt="Minimize" />
            </button>

            <button>
              <img src={maximizeDisabled} draggable="false" alt="Maximize" />
            </button>

            <button className="clickable" onClick={() => onExit()}>
              <img src={close} draggable="false" alt="Close" />
            </button>
          </ButtonGroup>
        </TitleBar>

        {menu}

        {children}
      </StyledFrame>
    </>
  );
}

export default Frame;
