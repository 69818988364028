import styled from "styled-components";
import { screens } from "../../../utils";
export const StyledFrame = styled.div.attrs(({ top, left }) => ({
  style: {
    top,
    left,
  },
}))`
  background-color: white; /* Set background to white */
  width: calc(100% - 10px);
  margin-top: -31px;
  border-radius: 10px;
  @media only screen and (min-width: ${screens.md}) {
    margin-top: 0px;
    width: ${(props) => props.width + "px"};
    max-width: 80%;
    position: absolute;
    border: solid 2px #000
    border-radius: 10px;
  }
  z-index: ${(props) => (props.blurred ? `10` : `20`)};
  padding: 4px 4px 4px 4px;
  display: ${(props) => (props.isMinimized ? `none` : `initial`)};

  div.Notepad {
    height: 300px;
    background-color: rgb(245, 245, 240);
    overflow: scroll;
    box-shadow: rgba(0, 0, 0, 0.8) 1.5px 1.5px 0 inset;
    border-right: 2px solid rgb(240, 240, 240);
    border-bottom: 2px solid rgb(240, 240, 240);
    box-sizing: border-box;
    padding: 3px 0 0 4px;
    font-size: 13px;
    border-radius: 10px;
  }
`;

export const TitleBar = styled.div`
  background: ${(props) => (props.blurred ? "rgb(0, 0, 255)" : "rgb(0, 0, 255)")}; // Plain blue background
  color: ${(props) => (props.blurred ? `rgb(200, 200, 200)` : `rgb(255, 255, 255)`)};
  cursor: move; // Ensure the cursor is set to move for the entire title bar
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 6px;
  border-radius: 6px 6px 0px 0px;
  height: 30px; // Set a height to ensure the cursor is active throughout

  span {
    padding-left: 4px;
    font-size: 11px;
    flex: 5;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 60px;
  button {
    text-align: center;
    margin-left: 2px;
    background-color: rgb(195, 199, 203);
    border: none;
    box-shadow: rgb(0, 0, 0) -1px -1px 1px inset,
      rgba(255, 255, 255, 0.8) 2px 2px 0 inset;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2px;
  }
  .clickable:active {
    box-shadow: rgb(0, 0, 0) 1px 1px 1px inset,
      rgba(255, 255, 255, 0.8) -1px -1px 0 inset;
    padding: 2.5px 1.5px 1.5px 2.5px;
  }
  button img {
    width: 13px;
  }
`;

export const StyledMenu = styled.div`
  display: flex;
  flex-direction: row;
  width: 25%;
  justify-content: space-between;
  span {
    padding: 5px 8px;
    margin: 2px 0;
  }
  span:hover {
    background-color: rgb(0, 0, 127);
    color: rgb(255, 255, 255);
  }
`;
