import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const StyledFooter = styled.div`
  margin: 0 auto;
  float: right;
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  font-size: 12px;
`;


function SocialMateFooter() {

  return (
    <StyledFooter>SocialMate Pty Ltd</StyledFooter>
  );
}

export default SocialMateFooter;