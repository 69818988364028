export function map(value, start1, stop1, start2, stop2, clip = false) {
  const shifted = value - start1;
  const range1 = stop1 - start1;
  const normalised = shifted / range1;

  const range2 = stop2 - start2;
  const newValue = normalised * range2 + start2;
  if (clip && newValue < start2) return start2;
  if (clip && newValue > stop2) return stop2;
  return newValue;
}

export function random(start, end) {
  return map(Math.random(), 0, 1, start, end);
}
export const screens = {
  sm: "640px",
  // => @media (min-width: 640px) { ... }

  md: "768px",
  // => @media (min-width: 768px) { ... }

  lg: "1024px",
  // => @media (min-width: 1024px) { ... }

  xl: "1280px",
  // => @media (min-width: 1280px) { ... }

  "2xl": "1536px",
};

