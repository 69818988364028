import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import WindowFrame from './WindowFrame/WindowFrame';
import { focusFaq, blurFaq, minimizeFaq, exitFaq } from '../../store/actions/actions';
import contact from '../../assets/taskbar-icons/contact.png';
import { useNavigate } from 'react-router-dom';
import { Widget } from '@typeform/embed-react'

const StyledContainer = styled.div`
padding-top: 5px;
padding-bottom: 5px;
  .images img {
    width: 80px;
    height: 90px;  
  }
  .container {
    display: flex;
    max-height: 600px;
    overflow-x: hidden;
    overflow-y: auto;
    .text {
    padding-left: 15px;
    padding-right: 15px;
      flex: 2;
      line-height: 18px;
      a {
        text-decoration: underline;
        color: rgb(0, 0, 127);
      }
      h1 {
        font-size: 15px;
        margin: 0;
      }
    }
  }
`;

function Faq({ faq, onFaqFocus, onFaqBlur, onFaqMinimize, onFaqExit }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  useEffect(() => {
    window.addEventListener('click', faqBlur);
    return () => window.removeEventListener('click', faqBlur);
    // eslint-disable-next-line
  }, []);

  function faqBlur(event) {
    if (!document.querySelector('#Faq').contains(event.target) &&
      !document.querySelector('#Faq-button').contains(event.target)) {
      onFaqBlur();
    } else { onFaqFocus(); }
  }

  const handleClickExit = () => {
    dispatch(exitFaq())
    navigate("/")
  }


  const displayContent =
    faq.show ?
      <WindowFrame
        id="Faq"
        x="140"
        y="100"
        width="920"
        height="285"
        img={contact}
        title="Apply Here"
        blurred={faq.blurred}
        showMenu={false}
        onMinimize={onFaqMinimize}
        onExit={handleClickExit}
        isMinimized={faq.minimized}>

        <StyledContainer>
          <div className="container">
          <Widget id="qCSqT34i" style={{ width: '100%', height: '500px' }}/>
            {/* <FaqList /> */}
            </div>
        </StyledContainer>

      </WindowFrame> : null;

  return displayContent;
}

const mapStateToProps = (state) => {
  return {
    faq: state.faq
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onFaqBlur: () => dispatch(blurFaq()),
    onFaqFocus: () => dispatch(focusFaq()),
    onFaqMinimize: () => dispatch(minimizeFaq()),
    onFaqExit: () => dispatch(exitFaq()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Faq);