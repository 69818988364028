import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import WindowFrame from "./WindowFrame/WindowFrame";
import {
  focusWeRunAds,
  blurWeRunAds,
  minimizeWeRunAds,
  exitWeRunAds,
} from "../../store/actions/actions";
import console_prompt from "../../assets/taskbar-icons/console_prompt-1.png";
import werunadsgif from "../../assets/about-images/werunads.gif";

const StyledContainer = styled.div`
  padding-top: 0px;
  padding-bottom: 0px;
  .images img {
    width: 980px;
    height: 90px;
  }

  .container {
    display: flex;
    overflow: visible;
    max-height: 600px;
    overflow-y: auto;
    justify-content: space-between;
    .text {
      padding-left: 15px;
      padding-right: 15px;
      text-align: left;
      flex: 2;
      line-height: 18px;
    }
  }
  .button-container {
    padding-top: 15px;
    text-align: right;
  }

  .column {
    flex-basis: 50%;
    text-align: center;
  }

  .pricing-table {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid black;
    border-radius: 15px;
    padding: 5px;
    margin-bottom: 16px;
    box-shadow: 8px 8px 0 black;
  }

  .plan {
    padding: 20px;
    text-align: center;
  }

  .plan-name {
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 5px;
  }

  .plan-price {
    font-size: 36px;
    font-weight: 600;
  }

  .plan-features {
    list-style: none;
    padding: 0;
    margin: 20px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .plan-features li {
    display: flex;
    align-items: center;
  }

  .tick-icon {
    margin-right: 5px;
    color: #0865ff;
  }

  .plan-button {
    display: block;
    width: 100%;
    background-color: #0865ff;
    padding: 15px 0;
    border-radius: 8px;
    transition: background-color 0.3s ease;
    margin-top: 20px;
  }

  .btn-text {
    color: #fff;
    font-size: 14px;
    font-weight: bold;
  }

  a {
    text-decoration: none !important;
    -webkit-text-decoration: none !important;
  }

  .text-center {
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    flex: 2;
    line-height: 18px;
  }

  .plan-button:hover {
    background-color: #006dff;
  }
`;

function WeRunAds({
  werunads,
  onWeRunAdsFocus,
  onWeRunAdsBlur,
  onWeRunAdsMinimize,
  onWeRunAdsExit,
}) {
  const navigate = useNavigate();

  const handleClickExit = useCallback(() => {
    console.log("Exit button clicked in WeRunAds");
    onWeRunAdsExit();
    console.log("onWeRunAdsExit called");
    setTimeout(() => {
      console.log("Navigating to /");
      navigate("/");
    }, 100);
  }, [onWeRunAdsExit, navigate]);

  useEffect(() => {
    console.log("WeRunAds component mounted");
    return () => {
      console.log("WeRunAds component unmounted");
    };
  }, []);

  useEffect(() => {
    console.log("WeRunAds show state:", werunads.show);
  }, [werunads.show]);

  if (!werunads.show) {
    console.log("WeRunAds not shown");
    return null;
  }

  console.log("Rendering WeRunAds");

  return (
    <WindowFrame
      id="WeRunAds"
      x={20}
      y={20}
      width="800"
      height="385"
      img={console_prompt}
      title="WeRunAds"
      blurred={werunads.blurred}
      showMenu={false}
      onMinimize={onWeRunAdsMinimize}
      onExit={handleClickExit}
      isMinimized={werunads.minimized}
    >
      <StyledContainer>
        <div className="container">
          <img src={werunadsgif} width='auto' height='100%' className="werunads" alt="we run ads" />
        </div>
      </StyledContainer>
    </WindowFrame>
  );
}

const mapStateToProps = (state) => ({
  werunads: state.werunads,
});

const mapDispatchToProps = {
  onWeRunAdsBlur: blurWeRunAds,
  onWeRunAdsFocus: focusWeRunAds,
  onWeRunAdsMinimize: minimizeWeRunAds,
  onWeRunAdsExit: exitWeRunAds,
};

export default connect(mapStateToProps, mapDispatchToProps)(WeRunAds);
