import React, { useState, useEffect } from 'react';

const ResponsiveASCIIArt = ({ art }) => {
  const [fontSize, setFontSize] = useState(16);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 640) {
        setFontSize(6);
      } else if (width < 768) {
        setFontSize(8);
      } else if (width < 1024) {
        setFontSize(12);
      } else {
        setFontSize(12);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <pre 
      className={`font-mono whitespace-pre overflow-x-auto`} 
      style={{ 
        fontSize: `${fontSize}px`,
        lineHeight: '1.2',
        fontFamily: 'monospace', 
        whiteSpace: 'pre' 
      }}
    >
      {art}
    </pre>
  );
};

const asciiArt = `
 .d8888b.                    d8b          888 888b     d888          888            
d88P  Y88b                   Y8P          888 8888b   d8888          888            
Y88b.                                     888 88888b.d88888          888            
 "Y888b.    .d88b.   .d8888b 888  8888b.  888 888Y88888P888  8888b.  888888 .d88b.  
    "Y88b. d88""88b d88P"    888     "88b 888 888 Y888P 888     "88b 888   d8P  Y8b 
      "888 888  888 888      888 .d888888 888 888  Y8P  888 .d888888 888   88888888 
Y88b  d88P Y88..88P Y88b.    888 888  888 888 888   "   888 888  888 Y88b. Y8b.     
 "Y8888P"   "Y88P"   "Y8888P 888 "Y888888 888 888       888 "Y888888  "Y888 "Y8888  
`;

export { ResponsiveASCIIArt, asciiArt };