import React, { useState, useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { connect } from "react-redux";
import Icons from "./components/Icons/Icons";
import TaskBar from "./components/TaskBar/TaskBar";
import About from "./components/Windows/About";
import Contact from "./components/Windows/Contact";
import WeRunAds from "./components/Windows/WeRunAds";
import Faq from "./components/Windows/Faq";
import Notepad from "./components/Windows/Notepad";
import ShutDown from "./components/ShutDown";
import Modal from "./components/Modal";
import startup from "./assets/sounds/startup.mp3";
import socialmate from "../src/assets/about-images/SocialMateIMG.png";
// import TypingComponent from "./components/TypingComponent";
import Screensaver from "./components/Screensaver";
const SECOND = 1000;
const IDLE_TIMEOUT = 140 * SECOND;

function App({
  aboutVisible,
  contactVisible,
  werunadsVisible,
  faqVisible,
  notepadVisible,
  shutDown,
  showModal,
}) {
  const [audio] = useState(new Audio(startup));

  useEffect(() => {
    window.addEventListener("contextmenu", contextDisable);
    audio.play();
    return () => {
      window.removeEventListener("contextmenu", contextDisable);
    };
    // eslint-disable-next-line
  }, []);

  function contextDisable(event) {
    event.preventDefault();
  }

  const [showScreensaver, setShowScreensaver] = useState(false);
  
  useEffect(() => {
    let timeout = null;
    const handleUserActivity = () => {
      // Reset the timeout on user activity
      if (timeout) clearTimeout(timeout);
      // Hide the screensaver when user activity is detected
      setShowScreensaver(false);

      timeout = setTimeout(() => {
        setShowScreensaver(true);
      }, IDLE_TIMEOUT);
    };
    // Add event listeners for user activity
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    return () => {
      // Clean up event listeners
      window.removeEventListener("mousemove", handleUserActivity);
      clearTimeout(timeout);
      window.removeEventListener('keydown', handleUserActivity);
    };
  }, []);

  const aboutDisplay = aboutVisible ? <About /> : null;
  const contactDisplay = contactVisible ? <Contact /> : null;
  const werunadsDisplay = werunadsVisible ? <WeRunAds /> : null;
  const faqDisplay = faqVisible ? <Faq /> : null;
  const notepadDisplay = notepadVisible ? <Notepad /> : null;
  const shutDownDisplay = shutDown ? <ShutDown /> : null;
  const modalDisplay = showModal ? <Modal /> : null;

  return (
    <div className="App">
      {!showScreensaver && (
        <div className="hero">
          <img src={socialmate} className="img-size" alt="SocialMate" />
          <h1 className="hero-text">
            We build DTC brands and campaigns.
          </h1>
          <p />
        </div>
      )}
      <Icons />
      {aboutDisplay}
      {notepadDisplay}
      {shutDownDisplay}
      {modalDisplay}
      {contactDisplay}
      {werunadsDisplay}
      {faqDisplay}
      <TaskBar />
      {showScreensaver && <Screensaver />}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    aboutVisible: state.about.show,
    contactVisible: state.contact.show,
    werunadsVisible: state.werunads.show,
    faqVisible: state.faq.show,
    notepadVisible: state.notepad.show,
    shutDown: state.shutDown,
    showModal: state.showModal,
  };
};

const ConnectedApp = connect(mapStateToProps)(App);

export const Root = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<ConnectedApp />}>
        <Route path="/creatives" element={<ConnectedApp />} />
        <Route path="/about" element={<ConnectedApp />} />
        <Route path="/notepad" element={<ConnectedApp />} />
        <Route path="/apply" element={<ConnectedApp />} />
        <Route path="/Zucc" element={<ConnectedApp />} />
      </Route>
    </Routes>
  </BrowserRouter>
);
