import React from "react";
import ReactDOM from "react-dom";
import { Root } from "./App.jsx";
import { createStore } from "redux";
import { Provider } from "react-redux";

import "./styles/global-styles.css";

import reducer from "./store/reducers/reducer";

const store = createStore(reducer);

ReactDOM.render(
  <Provider store={store}>
    <Root />
  </Provider>,
  document.getElementById("root")
);
