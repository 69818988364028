import React, { Component } from 'react';

const SIZE = 200;
const SPEED = 3;

class Block extends Component {
  constructor(props) {
    super(props);
    this.state = {
      x: Math.random() * this.props.windowW,
      y: Math.random() * this.props.windowH,
    };

    this.directionX = 1;
    this.directionY = 1;
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      const nextX = this.state.x + this.directionX * this.props.speed;
      const nextY = this.state.y + this.directionY * this.props.speed;

      if (nextX >= this.props.windowW) {
        this.directionX = -1;
      } else if (nextX <= 0) {
        this.directionX = 1;
      }

      if (nextY >= this.props.windowH) {
        this.directionY = -1;
      } else if (nextY <= 0) {
        this.directionY = 1;
      }

      this.setState({
        x: nextX,
        y: nextY,
      });
    }, 16);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div
        className="block"
        style={{
          left: this.state.x,
          top: this.state.y,
          width: this.props.size,
          height: this.props.size,
        }}
      ></div>
    );
  }
}

class Screensaver extends Component {
  render() {
    return (
      <div className="screensaver">
        {/* Your screensaver content */}
        <div id="root"></div>
        <Block
          size={SIZE}
          speed={SPEED}
          windowW={window.innerWidth - SIZE}
          windowH={window.innerHeight - SIZE}
        />
      </div>
    );
  }
}

export default Screensaver;
