export const SHUT_DOWN = 'SHUT_DOWN';

export const START_BUTTON_CLICKED = 'START_BUTTON_CLICKED';
export const START_MENU_BLUR = 'START_MENU_BLUR';

export const OPEN_NOTEPAD = 'OPEN_NOTEPAD';
export const OPEN_ABOUT = 'OPEN_ABOUT';
export const OPEN_CONTACT = 'OPEN_CONTACT';
export const OPEN_FAQ = 'OPEN_FAQ';
export const OPEN_WERUNADS = 'OPEN_WERUNADS';
export const OPEN_MODAL = 'OPEN_MODAL';

export const FOCUS_NOTEPAD = 'FOCUS_NOTEPAD';
export const FOCUS_ABOUT = 'FOCUS_ABOUT';
export const FOCUS_WERUNADS = 'FOCUS_WERUNADS';
export const FOCUS_FAQ = 'FOCUS_FAQ';
export const FOCUS_CONTACT = 'FOCUS_CONTACT';

export const BLUR_NOTEPAD = 'BLUR_NOTEPAD';
export const BLUR_ABOUT = 'BLUR_ABOUT';
export const BLUR_FAQ = 'BLUR_FAQ';
export const BLUR_WERUNADS = 'BLUR_WERUNADS';
export const BLUR_CONTACT = 'BLUR_CONTACT';

export const MINIMIZE_NOTEPAD = 'MINIMIZE_NOTEPAD';
export const MINIMIZE_FAQ = 'MINIMIZE_FAQ';
export const MINIMIZE_ABOUT = 'MINIMIZE_ABOUT';
export const MINIMIZE_WERUNADS = 'MINIMIZE_WERUNADS';
export const MINIMIZE_CONTACT = 'MINIMIZE_CONTACT';

export const MAXIMIZE_NOTEPAD = 'MAXIMIZE_NOTEPAD';
export const MAXIMIZE_FAQ = 'MAXIMIZE_FAQ';
export const MAXIMIZE_ABOUT = 'MAXIMIZE_ABOUT';
export const MAXIMIZE_WERUNADS = 'MAXIMIZE_WERUNADS';
export const MAXIMIZE_CONTACT = 'MAXIMIZE_CONTACT';

export const EXIT_NOTEPAD = 'EXIT_NOTEPAD';
export const EXIT_FAQ = 'EXIT_FAQ';
export const EXIT_WERUNADS = 'EXIT_WERUNADS';
export const EXIT_ABOUT = 'EXIT_ABOUT';
export const EXIT_CONTACT = 'EXIT_CONTACT';
export const EXIT_MODAL = 'EXIT_MODAL';